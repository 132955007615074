import React, { useMemo } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ProductsTab from './Products'
import CMSText from '../Global/CMSText'
import { useTranslation } from 'react-i18next'
import { ProductGroup } from '../../typings/base'

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 50,
  },
  rowCenter: {
    display: 'inline-flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: 50,
  },
  alignCenter: {
    textAlign: 'center',
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  loadMoreBtnLoader: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}))

type ProductListContainerProps = {
  products: ProductGroup['items']
  loadMore: boolean
  availableProductsOnly: boolean
  shopData: any
  settings: any
  hidePrice?: boolean
  basket?: any
  takeback?: any
  isDisplayedPrice?: boolean
  isLoadingProductsAndStocks: boolean
  isSearch?: boolean
  mainData?: any
  productSettings: any
  isSecondHandTaxon?: boolean
  currentPage: number
  handlePrev: () => void
  handleNext: () => void
  handleLoadMore: () => void
  loadingMoreProducts: boolean
}

const ProductListContainer = ({
  products,
  loadMore,
  availableProductsOnly,
  shopData,
  settings,
  hidePrice,
  basket,
  takeback,
  isDisplayedPrice,
  isLoadingProductsAndStocks,
  isSearch,
  mainData,
  productSettings,
  isSecondHandTaxon,
  currentPage,
  handlePrev,
  handleNext,
  handleLoadMore,
  loadingMoreProducts,
}: ProductListContainerProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const currentNumberShown = useMemo(() => {
    return products.page * products.limit > products.total
      ? products.total
      : products.page * products.limit
  }, [products.page, products.limit, products.total])

  return (
    <div>
      <ProductsTab
        loadMore={loadMore}
        availableProductsOnly={availableProductsOnly}
        shopData={shopData}
        settings={settings}
        hidePrice={hidePrice}
        basket={basket}
        takeback={takeback}
        isDisplayedPrice={isDisplayedPrice}
        isLoadingProductsAndStocks={isLoadingProductsAndStocks}
        isSearch={isSearch}
        mainData={mainData}
        productSettings={productSettings}
        isSecondHandTaxon={isSecondHandTaxon}
      />
      {products?.pages > 1 &&
        (!loadMore ? (
          <div className={classes.row}>
            {currentPage !== 1 ? (
              <Button
                variant={'contained'}
                color={'primary'}
                size={'small'}
                onClick={handlePrev}
              >
                <CMSText
                  asText
                  data={shopData?.data?.prev}
                  defaultText={t('texts:shop:prev')}
                />
              </Button>
            ) : (
              <div></div>
            )}
            {currentPage < products?.pages && (
              <Button
                variant={'contained'}
                color={'primary'}
                size={'small'}
                onClick={handleNext}
              >
                <CMSText
                  asText
                  data={shopData?.data?.next}
                  defaultText={t('texts:shop:next')}
                />
              </Button>
            )}
          </div>
        ) : (
          <div className={classes.rowCenter}>
            <div className={classes.alignCenter}>
              {products.total !== currentNumberShown && !loadingMoreProducts && (
                <Button
                  variant={'contained'}
                  color={'primary'}
                  size={'small'}
                  onClick={handleLoadMore}
                  disabled={loadingMoreProducts}
                >
                  <CMSText
                    asText
                    data={shopData?.data?.load_more_button}
                    defaultText={t('texts:shop:load_more_button')}
                  />
                </Button>
              )}
              {loadingMoreProducts && (
                <div className={classes.loadMoreBtnLoader}>
                  <CircularProgress size={20} />
                </div>
              )}
              <div className={classes.marginTop}>
                <CMSText
                  asText
                  data={shopData?.data?.you_have_seen}
                  defaultText={t('texts:shop:you_have_seen')}
                />{' '}
                {currentNumberShown}{' '}
                <CMSText
                  asText
                  data={shopData?.data?.articles_on}
                  defaultText={t('texts:shop:articles_on')}
                />{' '}
                {products.total}
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default React.memo(ProductListContainer)
