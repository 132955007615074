import React, { useEffect, useMemo, useState } from 'react'
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import ProductCard from './ProductCard'
import {
  checkProductAvailability,
  columnLayout,
  thumbnailRatio,
} from '@/utils/utils'
import { loadingDatas } from '../Global/LoadingDatas'
import Skeleton from '@material-ui/lab/Skeleton'
import CMSText from '../Global/CMSText'
import { useTranslation } from 'react-i18next'
import { ShopConfig } from '@/interfaces/common/shopConfig'
import { Image, ProductAvailability } from '@/typings/base'
import { PrismicGeneralConfig } from '@/interfaces/common/prismicGeneralConfig'
import { PrismicProduct } from '../../interfaces/common/prismicProduct'
import { preSelectedSkuAndCondition } from '../../utils/takeBack'
import { useTakeback } from '../../hooks/useTakeback'
import { RootState } from '../../redux/reducers/rootReducer'
import { connect } from 'react-redux'
import { Product } from '../../interfaces/products/shop'
import ProductGrid from './ProductGrid'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootFull: {
      marginTop: theme.spacing(3),
    },
    rootColumn: {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(0),
      },
    },
    col: {},
    md5: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: '20%',
        flexBasis: '20%',
      },
    },
    skeletonCard: {
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
    },
    skeletonImg: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
    },
    skeletonContent: {
      padding: 10,
    },
  })
)

const mapStateToProps = (state: RootState) => ({
  products: state.products.items?.items ?? [],
  stock: state.stock,
  takeBackState: state.takeBack,
  cart: state.cart,
})

type Tprops = {
  availableProductsOnly: boolean
  shopData?: ShopConfig
  mainData?: PrismicGeneralConfig
  settings?: any
  stock: any
  loadMore: boolean
  hidePrice?: boolean
  basket?: any
  takeback?: any
  isDisplayedPrice?: boolean
  isLoadingProductsAndStocks: boolean
  isSearch?: boolean
  isSecondHandTaxon?: boolean
  productSettings: PrismicProduct
} & ReturnType<typeof mapStateToProps>

const ProductsTab = ({
  products,
  stock,
  shopData,
  mainData,
  settings,
  availableProductsOnly,
  loadMore,
  hidePrice,
  basket,
  takeback,
  isDisplayedPrice = true,
  isLoadingProductsAndStocks,
  isSearch = false,
  isSecondHandTaxon,
  productSettings,
  takeBackState,
  cart,
}: Tprops) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const [unavailableProductCount, setUnavailableProductCount] = useState(0)
  const layout = settings?.page_shop_layout
  const columns = settings?.page_shop_desktop_columns
  const thumbRatio = settings?.thumbnail_ratio

  const cardWidth = settings?.medium_image_size
  const aspectRatio = thumbnailRatio(thumbRatio)
  const gridItemWidth = columnLayout(columns)
  const isFiveColumns = gridItemWidth === '20%'

  const showImagesSlider = useMemo(
    () => mainData?.data?.show_product_item_slider,
    [mainData?.data?.show_product_item_slider]
  )

  const isSM = useMediaQuery(theme.breakpoints.down('sm'))

  const sizeNotShown = mainData?.data?.sizes_not_shown_on_mobile && isSM

  const showSizedOnHover = mainData?.data?.sizes_on_hover

  const { productCheckState } = useTakeback()

  const states = useMemo(
    () => productCheckState(takeback?.data),
    [takeback?.data]
  )

  const [productsToShow, setProductsToShow] = useState<
    {
      product: Product
      thumb: Image[]
      productAvailability: ProductAvailability
    }[]
  >([])

  useEffect(() => {
    if (
      products &&
      stock &&
      stock.stock &&
      !takeBackState.loading &&
      !isLoadingProductsAndStocks
    ) {
      const newProductsToShow = products
        .map((product) => {
          const productAvailability = checkProductAvailability(stock, product)

          if (
            product.enabled &&
            availableProductsOnly &&
            !productAvailability.available &&
            !productAvailability.secondHandAvailable
          ) {
            return null
          }

          const thumb = !showImagesSlider
            ? product?.images?.slice(0, 1)
            : processProductImages(
                product,
                productAvailability,
                stock,
                takeBackState,
                states,
                cart
              )

          return {
            product,
            thumb,
            productAvailability,
          }
        })
        .filter(Boolean)

      setProductsToShow(newProductsToShow)
      setUnavailableProductCount(products.length - newProductsToShow.length)
    }
  }, [
    products,
    stock.stock,
    isLoadingProductsAndStocks,
    takeBackState.loading,
    showImagesSlider,
    availableProductsOnly,
    states,
  ])

  const processProductImages = useMemo(
    () =>
      (
        product: Product,
        productAvailability: ProductAvailability,
        stock: any,
        takeBackState: any,
        states: any,
        cart: any
      ) => {
        if (
          !isSecondHandTaxon ||
          !productAvailability.secondHandImage?.length
        ) {
          return product?.images || []
        }

        const currentProductSkus = Object.values(product.variants).map(
          (v) => v.sku
        )
        const secondHandStock = new Set(
          currentProductSkus
            .map((sku) => stock.stockMap?.get(sku))
            .filter(Boolean)
        )
        const takeBackStock = new Set(
          currentProductSkus
            .map((sku) => takeBackState.takeBackStockMap?.get(sku))
            .filter(Boolean)
        )

        if (!secondHandStock.size || !takeBackStock.size) {
          return productAvailability.secondHandImage.concat(product?.images)
        }

        const chosen = preSelectedSkuAndCondition(
          Array.from(secondHandStock),
          product as any,
          Array.from(takeBackStock),
          states,
          cart,
          Boolean
        )

        const images = chosen.item?.images as Image[]
        return images?.length
          ? (Array.from(new Set(images.map((img) => img.path)).values()).map(
              (path) => ({ path })
            ) as Image[])
          : productAvailability.secondHandImage.concat(product?.images)
      },
    [isSecondHandTaxon]
  )

  return (
    <>
      <Grid
        container
        component="section"
        spacing={3}
        className={
          layout
            ? `shop__grid ${classes.rootColumn}`
            : `shop__grid ${classes.rootFull}`
        }
      >
        <>
          {loadMore &&
            productsToShow &&
            stock &&
            stock.stock &&
            !isLoadingProductsAndStocks && (
              <ProductGrid
                productsToShow={productsToShow}
                availableProductsOnly={availableProductsOnly}
                layout={layout}
                isFiveColumns={isFiveColumns}
                gridItemWidth={gridItemWidth}
                settings={settings}
                shopData={shopData}
                productSettings={productSettings}
                aspectRatio={aspectRatio}
                cardWidth={cardWidth}
                hidePrice={hidePrice}
                basket={basket}
                takeback={takeback}
                isDisplayedPrice={isDisplayedPrice}
                isSecondHandTaxon={isSecondHandTaxon}
                mainData={mainData}
                showSizedOnHover={showSizedOnHover}
                sizeNotShown={sizeNotShown}
                showImagesSlider={showImagesSlider}
              />
            )}
          {isLoadingProductsAndStocks &&
            loadingDatas?.map((loadingData) => (
              <Grid
                item
                key={`item-${loadingData.name}`}
                xs={6}
                md={4}
                lg={layout ? 4 : isFiveColumns ? 2 : gridItemWidth}
                className={`${
                  isFiveColumns ? classes.md5 : classes.col
                } shop__grid__item`}
              >
                <div className={classes.skeletonCard}>
                  <div data-ratio={aspectRatio}>
                    <Skeleton variant="rect" className={classes.skeletonImg} />
                  </div>
                  <div className={classes.skeletonContent}>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" width="60%" />
                    <Skeleton variant="text" width="30%" />
                  </div>
                </div>
              </Grid>
            ))}
          {!isLoadingProductsAndStocks &&
            unavailableProductCount >= productsToShow?.length && (
              <Grid item xs={12}>
                <Typography variant={'h4'} align="center">
                  {isSearch ? (
                    <CMSText
                      data={mainData?.data?.search_not_found_text}
                      defaultText={t('texts:shop:search_not_found')}
                      asText
                    />
                  ) : (
                    <CMSText
                      data={shopData?.data?.no_stock_available}
                      defaultText={t('texts:shop:no_stock_available')}
                      asText
                    />
                  )}
                </Typography>
              </Grid>
            )}
          {productsToShow &&
            stock &&
            !loadMore &&
            stock.stock &&
            !isLoadingProductsAndStocks && (
              <ProductGrid
                productsToShow={productsToShow}
                availableProductsOnly={availableProductsOnly}
                layout={layout}
                isFiveColumns={isFiveColumns}
                gridItemWidth={gridItemWidth}
                settings={settings}
                shopData={shopData}
                productSettings={productSettings}
                aspectRatio={aspectRatio}
                cardWidth={cardWidth}
                hidePrice={hidePrice}
                basket={basket}
                takeback={takeback}
                isDisplayedPrice={isDisplayedPrice}
                isSecondHandTaxon={isSecondHandTaxon}
                mainData={mainData}
                showSizedOnHover={showSizedOnHover}
                sizeNotShown={sizeNotShown}
                showImagesSlider={showImagesSlider}
              />
            )}
        </>
      </Grid>
    </>
  )
}

export default connect(mapStateToProps)(ProductsTab)
