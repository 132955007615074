import React, { useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import {
  makeStyles,
  createStyles,
  Grid,
  useMediaQuery,
  useTheme,
  Typography,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
} from '@material-ui/core'
import useShopStyles from '@/components/Shop/Shop.style'
import { connect } from 'react-redux'
import { ProductGroup, TaxonGroup } from '@/typings/base'
import Link from '../Link/Link'
import Button from '../Button/Button'
import { KeyboardArrowRight, UnfoldMore } from '@material-ui/icons'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import Commitment from '../Commitment/Commitment'
import TuneIcon from '@material-ui/icons/Tune'
import FlatRate from '../ClassicSub/FlatRate'
import { useScrollState } from '@/utils/scrollState'
import CMSText, { prismicText } from '../Global/CMSText'
import { useRouter } from 'next/router'
import { useSticky } from '@/utils/useSticky'
import { useTranslation } from 'react-i18next'
import FilterListSharpIcon from '@mui/icons-material/FilterListSharp'

import { ShopConfig } from '@/interfaces/common/shopConfig'
import { isSecondHandOnly } from '@/utils/constants'
import { RootState } from '@/redux/reducers/rootReducer'
import { PrismicProduct } from '@/interfaces/common/prismicProduct'
import { getProductsByTaxonsSlug } from '@/redux/actions/products'
import { GetMapDispatchTypes } from '@/interfaces/common/redux'
import ProductListContainer from './ProductListContainer'

const useStyles = makeStyles((theme) =>
  createStyles({
    cta: {
      [theme.breakpoints.down('md')]: {},
    },
    filters: {
      [theme.breakpoints.up('md')]: {
        height: '100%', // Need it for sticky effect on filters
      },
    },
    sticky: {
      [theme.breakpoints.up('md')]: {
        top: theme.spacing(2),
        position: 'sticky',
      },
    },
    filterList: {
      listStyle: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      margin: 0,
      padding: 0,
    },
    filterListItem: {
      margin: theme.spacing(0.5),
    },
    filterTitle: {
      flex: 1,
    },
    catButton: {
      '&:disabled': {
        color: theme.palette.common.white,
      },
    },
    onlyAvailable: {
      margin: theme.spacing(1, 0, 0, 0),
    },
    loaderContainer: {
      height: 500,
      width: '100%',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    loadMoreBtnLoader: {
      width: '100%',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    row: {
      display: 'inline-flex',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: 50,
    },
    rowCenter: {
      display: 'inline-flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: 50,
    },
    rowLeft: {
      display: 'inline-flex',
      justifyContent: 'left',
      alignItems: 'center',
      width: '100%',
    },
    countFilters: {
      marginLeft: theme.spacing(0.5),
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 10,
      minWidth: 20,
      width: 20,
      height: 20,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    alignCenter: {
      textAlign: 'center',
    },
    marginTop: {
      marginTop: theme.spacing(1),
    },
    catButtonColumn: {
      marginRight: theme.spacing(3),
    },
  })
)

const mapStateToProps = (state: RootState) => ({
  products: state.products,
  stock: state.stock,
  taxons: state.taxons,
})

const mapDispatchToProps = {
  getProductsByTaxonsSlug: getProductsByTaxonsSlug,
}

type TCatalogProps = {
  basket?: any
  theme?: any
  categories?: TaxonGroup
  shopData: ShopConfig
  mainData?: any
  isPack: boolean
  layout: boolean
  settings: any
  productSettings: PrismicProduct
  classic_sub_text?: any
  setIsDrawerOpen: any
  products: ProductGroup
  loadMore: boolean
  hidePrice?: boolean
  handleNext: () => void
  handleLoadMore: () => void
  handlePrev: () => void
  handleSort: (sort: Record<string, 'ASC' | 'DESC'>) => void
  isFiltered: boolean
  currentPage: any
  selectedCategory: any
  takeback?: any
  isDisplayedPrice?: boolean
  isLoadingProductsAndStocks: boolean
  hideFilters?: boolean
  hideAvailableProductsOnly?: boolean
  isSearch?: boolean
  sort?: Record<string, 'ASC' | 'DESC'>
} & ReturnType<typeof mapStateToProps> &
  GetMapDispatchTypes<typeof mapDispatchToProps>

const Catalog = ({
  categories,
  products,
  stock,
  taxons,
  setIsDrawerOpen,
  settings,
  isPack,
  classic_sub_text,
  shopData,
  hidePrice,
  loadMore,
  basket,
  currentPage,
  handleNext,
  handlePrev,
  handleLoadMore,
  handleSort,
  takeback,
  isDisplayedPrice = true,
  isLoadingProductsAndStocks,
  hideFilters = false,
  hideAvailableProductsOnly = false,
  isSearch = false,
  mainData,
  productSettings,
  getProductsByTaxonsSlug,
  sort,
}: TCatalogProps) => {
  const router = useRouter()
  const { t } = useTranslation()
  const category = router?.query?.slug
  const LayoutStyle = {
    isZwiterHandCategory: ['second-hand', 'zwiter-hand'].includes(
      category?.[0]
    ),
  }
  const shopClasses = useShopStyles(LayoutStyle)
  const classes = useStyles()
  const theme = useTheme()
  const layout = settings?.page_shop_layout
  const isLG = useMediaQuery(theme.breakpoints.up('lg'))
  const isMD = useMediaQuery(theme.breakpoints.up('md'))
  const gridSpacing = isLG ? 4 : layout ? 1 : 2
  const { trackEvent } = useMatomo()
  const isClassicSub = process.env.STORE_TYPE === 'classic_subscription'
  const [availableProductsOnly, setAvailableProductsOnly] = useState(
    typeof window !== 'undefined' &&
      localStorage?.getItem('availableProductsOnly')
      ? JSON.parse(localStorage?.getItem('availableProductsOnly'))
      : true
  )

  const isSecondHandTaxon =
    isSecondHandOnly === 'true'
      ? true
      : ['second-hand', 'zwiter-hand'].includes(category?.[0])

  const loadingMoreProducts = useMemo(
    () => products.loadingCumulative || stock.loading || taxons.loading,
    [products.loadingCumulative, stock.loading, taxons.loading]
  )

  const [
    enableSorting,
    enableSortingByPriceAsc,
    enableSortingByPriceDesc,
    enableSortingByDateAsc,
    enableSortingByDateDesc,
  ] = useMemo(
    () => [
      shopData?.data?.sort_by_price_asc ||
        shopData?.data?.sort_by_date_asc ||
        shopData?.data?.sort_by_price_desc ||
        shopData?.data?.sort_by_date_desc,
      shopData?.data?.sort_by_price_asc,
      shopData?.data?.sort_by_price_desc,
      shopData?.data?.sort_by_date_asc,
      shopData?.data?.sort_by_date_desc,
    ],
    [
      shopData?.data?.sort_by_price_asc,
      shopData?.data?.sort_by_date_asc,
      shopData?.data?.sort_by_price_desc,
      shopData?.data?.sort_by_date_desc,
    ]
  )

  const sortingOptions = useMemo(() => {
    const options = [
      {
        value: '',
        label: t('texts:shop:sort'),
      },
    ]
    if (enableSortingByPriceAsc) {
      options.push({
        value: 'price:ASC',
        label: prismicText(
          shopData?.data?.sort_by_price_asc_label,
          t('texts:shop:sort_by_price_asc')
        ),
      })
    }
    if (enableSortingByPriceDesc) {
      options.push({
        value: 'price:DESC',
        label: prismicText(
          shopData?.data?.sort_by_price_desc_label,
          t('texts:shop:sort_by_price_desc')
        ),
      })
    }
    if (enableSortingByDateDesc) {
      options.push({
        value: 'date:DESC',
        label: prismicText(
          shopData?.data?.sort_by_date_desc_label,
          t('texts:shop:sort_by_date_desc')
        ),
      })
    }
    if (enableSortingByDateAsc) {
      options.push({
        value: 'date:ASC',
        label: prismicText(
          shopData?.data?.sort_by_date_asc_label,
          t('texts:shop:sort_by_date_asc')
        ),
      })
    }
    return options
  }, [
    t,
    enableSortingByPriceAsc,
    enableSortingByPriceDesc,
    enableSortingByDateAsc,
    enableSortingByDateDesc,
  ])

  const sortingValue = useMemo(() => {
    if (!sort) return ''
    const key = Object.keys(sort)?.[0]
    return key ? `${key}:${sort[key]}` : ''
  }, [sort])

  const handleSortChange = (event) => {
    const { value } = event.target
    const { slug } = router.query
    trackEvent({
      category: 'Shop',
      action: `User sorting by ${value} in ${slug}`,
    })
    const filtersStored = localStorage.getItem('Filters')

    let sort
    if (value) {
      const [sortField, sortDirection] = value.split(':')
      sort = { [sortField]: sortDirection }
    }

    handleSort(sort)
    getProductsByTaxonsSlug(
      Array.isArray(slug) ? slug[slug.length - 1] : slug,
      48,
      1,
      JSON.parse(filtersStored),
      null,
      null,
      isSecondHandTaxon,
      sort
    )
  }

  useEffect(() => {
    if (hideFilters) {
      setAvailableProductsOnly(settings?.search_only_available_products)
    } else {
      if (!shopData?.data?.show_only_available_checkbox) {
        setAvailableProductsOnly(shopData?.data?.show_only_available_products)
      }
    }
  }, [
    shopData?.data?.show_only_available_checkbox,
    shopData?.data?.show_only_available_products,
    hideFilters,
  ])

  const onlyAvailableText = (
    <CMSText
      asText
      data={shopData?.data?.show_only_available_text}
      defaultText={t('texts:shop:availableOnly')}
    />
  )
  const scrollState = useScrollState()
  const isSticky = useSticky(layout)

  const toggleAvailableProductsOnly = useCallback(
    (isAvailableProductsOnly) => () => {
      setAvailableProductsOnly(isAvailableProductsOnly)
      localStorage.setItem(
        'availableProductsOnly',
        isAvailableProductsOnly.toString()
      )
    },
    []
  )

  return (
    <div
      id={'catalog'}
      data-product={isLoadingProductsAndStocks ? 'loading' : 'loaded'}
    >
      {(!categories || categories?.items?.[0]?.children.length !== 0) &&
        (layout ? (
          <>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12} md={4}>
                <div className={classes.filters}>
                  <Typography
                    component="p"
                    variant="h3"
                    className="shop-claim__title"
                  >
                    <CMSText
                      asText
                      data={shopData?.data?.titleshop}
                      defaultText={t('texts:shop:titleshop')}
                    />
                  </Typography>
                  <Typography
                    component="p"
                    variant="body1"
                    color="secondary"
                    className="shop-claim__desc"
                  >
                    <strong>
                      <CMSText
                        data={shopData?.data?.shopdescription}
                        defaultText={t('texts:shop:shopdescription')}
                      />
                    </strong>
                  </Typography>
                  {isMD && !isClassicSub && (
                    <Commitment
                      desc={prismicText(
                        shopData?.data?.shippingdescription,
                        t('shop:shippingdescription')
                      )}
                      icon={
                        shopData?.data?.shippingimage?.url
                          ? shopData?.data?.shippingimage?.url
                          : null
                      }
                    />
                  )}
                  {isClassicSub && <FlatRate doc={classic_sub_text} t={t} />}
                </div>
              </Grid>
              <Grid item xs={12} md={7} lg={8}>
                <>
                  {shopData?.data?.show_only_available_checkbox && (
                    <div className={classes.rowLeft}>
                      <div className={classes.catButtonColumn}>
                        <Button
                          size="small"
                          startIcon={<TuneIcon />}
                          variant="outlined"
                          color="primary"
                          onClick={() => setIsDrawerOpen(true)}
                          className={clsx(
                            'shop-filters',
                            shopClasses.shopFilters
                          )}
                        >
                          <span>
                            {
                              <CMSText
                                data={shopData?.data?.filter}
                                defaultText={t('texts:shop:filter')}
                                asText
                              />
                            }
                          </span>
                          {typeof window !== 'undefined' &&
                            JSON.parse(localStorage?.getItem('Filters'))
                              ?.length > 0 && (
                              <span className={classes.countFilters}>
                                {
                                  JSON.parse(localStorage.getItem('Filters'))
                                    ?.length
                                }
                              </span>
                            )}
                        </Button>
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={availableProductsOnly}
                              onChange={toggleAvailableProductsOnly(
                                !availableProductsOnly
                              )}
                              name="availability_check"
                              data-testid={'availability_check'}
                            />
                          }
                          label={
                            <Typography variant={'body2'}>
                              {onlyAvailableText}
                            </Typography>
                          }
                        />
                      </div>
                    </div>
                  )}
                  <ProductListContainer
                    products={products.items}
                    loadMore={loadMore}
                    availableProductsOnly={availableProductsOnly}
                    shopData={shopData}
                    settings={settings}
                    hidePrice={hidePrice}
                    basket={basket}
                    takeback={takeback}
                    isDisplayedPrice={isDisplayedPrice}
                    isLoadingProductsAndStocks={isLoadingProductsAndStocks}
                    isSearch={isSearch}
                    mainData={mainData}
                    productSettings={productSettings}
                    isSecondHandTaxon={isSecondHandTaxon}
                    currentPage={currentPage}
                    handlePrev={handlePrev}
                    handleNext={handleNext}
                    handleLoadMore={handleLoadMore}
                    loadingMoreProducts={loadingMoreProducts}
                  />
                </>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <div className={'spy'} />
            <div
              className={clsx(
                'sticky-bar',
                'sticky-bar--shop',
                shopClasses.stickyBar,
                isSticky && [
                  shopClasses.stickyBarSticked,
                  'sticky-bar--sticked',
                ],
                scrollState.showOnTop && [
                  shopClasses.stickyBarTop,
                  'sticky-bar--top',
                ],
                scrollState.showOnScrollUp && [
                  shopClasses.stickyBarScrollUp,
                  'sticky-bar--scrollup',
                ]
              )}
            >
              <>
                <div
                  className={clsx(
                    'shop-menu',
                    'shop-menu--products',
                    shopClasses.shopMenu
                  )}
                >
                  {!hideFilters && (
                    <Button
                      size="small"
                      startIcon={<TuneIcon />}
                      variant="outlined"
                      color="primary"
                      onClick={() => setIsDrawerOpen(true)}
                      className={clsx('shop-filters', shopClasses.shopFilters)}
                    >
                      <span>
                        {
                          <CMSText
                            data={shopData?.data?.filter}
                            defaultText={t('texts:shop:filter')}
                            asText
                          />
                        }
                      </span>
                      {typeof window !== 'undefined' &&
                        JSON.parse(localStorage?.getItem('Filters'))?.length >
                          0 && (
                          <span className={classes.countFilters}>
                            {
                              JSON.parse(localStorage.getItem('Filters'))
                                ?.length
                            }
                          </span>
                        )}
                    </Button>
                  )}
                  <div className={shopClasses.shopMenuRightControls}>
                    {enableSorting && (
                      <FormControl
                        variant="outlined"
                        className={clsx(
                          'shop-menu__right-controls',
                          shopClasses.shopMenuRightControls
                        )}
                      >
                        <div
                          className={clsx(
                            'shop-menu__right-controls__icon-container',
                            shopClasses.shopMenuRightControlsIconContainer
                          )}
                        >
                          <FilterListSharpIcon className="shop-menu__right-controls__icon" />
                        </div>
                        <Select
                          className="shop-menu__sort-input"
                          defaultValue={''}
                          displayEmpty
                          IconComponent={UnfoldMore}
                          onChange={handleSortChange}
                          value={sortingValue}
                        >
                          {sortingOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    {isPack && !isSticky && !LayoutStyle.isZwiterHandCategory && (
                      <div
                        className={clsx(
                          'shop-menu__cta',
                          shopClasses.shopMenuCta
                        )}
                      >
                        <Link href="/packs">
                          <a>
                            <Button
                              className={clsx(
                                'shop-menu__cta-link',
                                classes.cta
                              )}
                              variant="outlined"
                              component="span"
                              onClick={() =>
                                trackEvent({
                                  category: 'Shop',
                                  href: '/packs',
                                  action: 'Shop to packs',
                                })
                              }
                              size="small"
                              color="primary"
                              endIcon={<KeyboardArrowRight />}
                            >
                              {prismicText(
                                shopData?.data?.shop_to_packs_button,
                                t('texts:shop:shop_to_packs_button')
                              )}
                            </Button>
                          </a>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                {!hideAvailableProductsOnly &&
                  shopData?.data?.show_only_available_checkbox && (
                    <div className={`onlyAvailable ${classes.onlyAvailable}`}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={availableProductsOnly}
                            onChange={() =>
                              toggleAvailableProductsOnly(
                                !availableProductsOnly
                              )
                            }
                            name="availability_check"
                            data-testid={'availability_check'}
                            className={'onlyAvailable__check'}
                          />
                        }
                        label={
                          <Typography
                            variant={'caption'}
                            className={'onlyAvailable__label'}
                          >
                            {onlyAvailableText}
                          </Typography>
                        }
                      />
                    </div>
                  )}
              </>
            </div>
            <ProductListContainer
              products={products.items}
              loadMore={loadMore}
              availableProductsOnly={availableProductsOnly}
              shopData={shopData}
              settings={settings}
              hidePrice={hidePrice}
              basket={basket}
              takeback={takeback}
              isDisplayedPrice={isDisplayedPrice}
              isLoadingProductsAndStocks={isLoadingProductsAndStocks}
              isSearch={isSearch}
              mainData={mainData}
              productSettings={productSettings}
              isSecondHandTaxon={isSecondHandTaxon}
              currentPage={currentPage}
              handlePrev={handlePrev}
              handleNext={handleNext}
              handleLoadMore={handleLoadMore}
              loadingMoreProducts={loadingMoreProducts}
            />
          </>
        ))}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Catalog)
