import React from 'react'
import { Grid, GridSize, makeStyles } from '@material-ui/core'
import ProductCard from './ProductCard'
import { ProductAvailability, Image } from '@/typings/base'
import { Product } from '../../interfaces/products/shop'

const useStyles = makeStyles((theme) => ({
  col: {},
  md5: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '20%',
      flexBasis: '20%',
    },
  },
}))

type ProductGridProps = {
  productsToShow: {
    product: Product
    thumb: Image[]
    productAvailability: ProductAvailability
  }[]
  availableProductsOnly: boolean
  layout: boolean
  isFiveColumns: boolean
  gridItemWidth: GridSize
  settings: any
  shopData: any
  productSettings: any
  aspectRatio: number
  cardWidth: number
  hidePrice?: boolean
  basket?: any
  takeback?: any
  isDisplayedPrice?: boolean
  isSecondHandTaxon?: boolean
  mainData?: any
  showSizedOnHover?: boolean
  sizeNotShown?: boolean
  showImagesSlider?: boolean
}

const ProductGrid = ({
  productsToShow,
  availableProductsOnly,
  layout,
  isFiveColumns,
  gridItemWidth,
  settings,
  shopData,
  productSettings,
  aspectRatio,
  cardWidth,
  hidePrice,
  basket,
  takeback,
  isDisplayedPrice,
  isSecondHandTaxon,
  mainData,
  showSizedOnHover,
  sizeNotShown,
  showImagesSlider,
}: ProductGridProps) => {
  const classes = useStyles()

  return (
    <>
      {productsToShow?.map(({ product, thumb, productAvailability }, index) => {
        if (availableProductsOnly && product.enabled) {
          if (
            productAvailability.available ||
            productAvailability.secondHandAvailable
          ) {
            return (
              <Grid
                item
                key={product.code}
                xs={6}
                md={4}
                lg={layout ? 4 : isFiveColumns ? 2 : gridItemWidth}
                className={`${
                  isFiveColumns ? classes.md5 : classes.col
                } shop__grid__item`}
              >
                <ProductCard
                  addButton
                  key={product.code}
                  settings={settings}
                  shopData={shopData}
                  productSettings={productSettings}
                  index={index}
                  productCode={product.code}
                  slug={product.slug}
                  name={product.name}
                  thumb={thumb}
                  thumbRatio={aspectRatio}
                  thumbWidth={cardWidth}
                  product={product}
                  hidePrice={hidePrice}
                  basket={basket}
                  takeback={takeback}
                  isDisplayedPrice={isDisplayedPrice}
                  isSecondHandTaxon={isSecondHandTaxon}
                  showSizesOnHover={showSizedOnHover}
                  sizeNotShown={sizeNotShown}
                  showImagesSlider={showImagesSlider}
                  productAvailability={productAvailability}
                />
              </Grid>
            )
          }
          return null
        }

        return (
          <Grid
            item
            key={product.code}
            xs={6}
            md={4}
            lg={layout ? 4 : isFiveColumns ? 2 : gridItemWidth}
            className={`${
              isFiveColumns ? classes.md5 : classes.col
            } shop__grid__item`}
          >
            <ProductCard
              addButton
              key={product.code}
              shopData={shopData}
              settings={settings}
              index={index}
              productSettings={productSettings}
              productCode={product.code}
              slug={product.slug}
              name={product.name}
              thumb={thumb}
              thumbRatio={aspectRatio}
              thumbWidth={cardWidth}
              product={product}
              hidePrice={hidePrice}
              basket={basket}
              isDisplayedPrice={isDisplayedPrice}
              takeback={takeback}
              isSecondHandTaxon={isSecondHandTaxon}
              mainData={mainData}
              showSizesOnHover={showSizedOnHover}
              sizeNotShown={sizeNotShown}
              showImagesSlider={showImagesSlider}
              productAvailability={productAvailability}
            />
          </Grid>
        )
      })}
    </>
  )
}

export default React.memo(ProductGrid)
